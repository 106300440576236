import { FaFacebookF, FaInstagram } from "react-icons/fa";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  americanexp,
  card1,
  card2,
  card3,
  discover,
  logoFooter,
} from "../../assets";
import { RiInstagramFill } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { API } from "../../api";
import { errorToast, successToast } from "../../hooks/useToast";

const ExamFooter = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await API.registerNewsletter(data);
      successToast(
        "We have received your query! Someone will get in touch with you soon."
      );
      reset();
      setValue("email", "");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not submit your query! Try again later");
      console.log(error);
    }
  };

  return (
    <div className="w-full bg-themeSecondryDark-0 flex flex-col items-center gap-4 py-[10vh] md:py-14">
      <img
        src={logoFooter}
        alt="Header_logo"
        width={500}
        height={500}
        className=" max-w-[300px]  "
      />

      <p className="text-thin text-sm text-white">
        © 2025 Q Bank Model. All Rights Reserved.
      </p>
    </div>
  );
};

export default ExamFooter;
