import React, { useState, useEffect } from "react";

const DailyCounter = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = new Date(targetDate).getTime() - new Date().getTime();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();

    return () => clearInterval(timer);
  }, [targetDate]);

  const timeUnits = [
    { value: timeLeft.days, label: "Days" },
    { value: timeLeft.hours, label: "Hours" },
    { value: timeLeft.minutes, label: "Minutes" },
    { value: timeLeft.seconds, label: "Seconds" },
  ];

  return (
    <div className="flex gap-4 p-0 rounded-lg justify-center md:justify-between">
      {timeUnits.map((unit, index) => (
        <div key={unit.label} className="text-center min-w-16 bg-white rounded-xl p-1">
          <div className="text-2xl font-bold text-themeSecondry-0 mb-0">
            {unit.value.toString().padStart(2, "0")}
          </div>
          <div
            className={`text-sm font-semibold ${
              index === 0 ? "text-themeButton-0" : "text-themeButton-0"
            }`}
          >
            {unit.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DailyCounter;
